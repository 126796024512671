import React from "react";
import { Link } from "react-router-dom";
import Badge from "../../images/Badge.png";
import selpteam from "../../images/selpteam.png";
import participant from "../../images/participant.png";
import pic1 from "../../images/pic1.png";
import pic2 from "../../images/pic2.png";
import pic3 from "../../images/pic3.png";
import ayo from "../../images/ayo.jpeg";
import gabriel from "../../images/gabriel.jpeg";
import joshua from "../../images/joshua.jpeg";
import kazeem from "../../images/kazeem.jpeg";
import kenn from "../../images/kenn.jpeg";
import olusola from "../../images/olusola.jpg";
import oyekan from "../../images/oyekan.jpeg";
import xische from "../../images/xische.png";
import workang from "../../images/workang.jpeg";
import OwlCarousel from "react-owl-carousel";
import Openhouse from "../../images/Openhouse.png";
import Daily from "../../images/Daily.png";
import retro from "../../images/retro.png";
import online from "../../images/online.png";
import sprint from "../../images/sprint.png";
import community from "../../images/community.png";
import feedback from "../../images/feedback.png";
import collaborative from "../../images/collaborative.png";
import Syllabus from "../../documents/Syllabus.pdf";

function HomePage() {
  var currentYear = new Date().getFullYear();
  return (
    <div className="HomePage--wrapper-con">
      <div className="HomePage--wrapper">
        <div className="HomePage--Top-layout">
          <div className="HomePage--Top-container">
            <div className="HomePage--Top-">
              <div className="Homepage--year">IVYARC</div>
              <div className="Homepage--role">Software</div>
              <div className="Homepage--role">Engineering</div>
              <div className="Homepage--Leadership">
                Leadership Programme (SELP)
              </div>
            </div>
            <div>
              <div className="Badge-img-wrapper">
                <img className="Badge-img" src={Badge} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="Welcome-datetime-liner"/>
        <div className="Welcome-datetime-container">
          <div className="Welcome-datetime-wrapper">
            <div className="Welcome-datetime-card Welcome-datetime-card-bottom">
              <div className="welcome--date">Programme Duration</div>
              <div className="welcome--time">Dec 8, 2021 - March 11, 2022</div>
              <div className="welcome--content">
              12-week online programme divided into six <br/>key learning modules.
              </div>

              <div>*Career placement for top performers.</div>
              <br></br>
              <div className='welcome--content'>
                <Link to='/CourseSyllabus'>
                  <button>Download Course Syllabus</button>
                </Link>
              </div>
            </div>

            <div className="Welcome-datetime-card Welcome-datetime-card-bottom">
              <div className="welcome--date">Format</div>
              <div className="welcome--time">Online</div>
              <div className="welcome--content">
                All modules of the course will be virtually taught by articulate
                instructors. Thus, participants are expected to have a capable
                computer and a stable internet connection to actively learn.
              </div>
            </div>

            <div className="Welcome-datetime-card">
              <div className="welcome--date">Programme fee</div>
              <div className="welcome--time">₦ 20,000</div>
              <div className="welcome--content">
                The Course fee will be paid upon programme
                commencement
              </div>
            </div>
          </div>
        </div>
        <div className="HomePage--content-container">
          <div className="HomePage--content-card1 HomePage--content-card">
            <div>
              <div className="HomePage--content-button-con">
                <Link to="/RegistrationForm">
                  <button>Apply today!</button>
                </Link>
              </div>

              <h3 className="card-title pb-3">
                IVYARC Software Engineering Leadership Program
              </h3>
              <div className="HomePage--content-Placement HomePage--content-button-contain2">
                <div>
                  The IVYARC Software Engineering Leadership Program (SELP) is
                  an expedited pathway that will help you become a
                  value-driven engineering leader. SELP participants develop
                  an advanced engineering toolkit; including
                  entrepreneurial-mindset, collaboration and continuous learning
                  etc.
                  <div>
                    <br/>
                  </div>
                  <div>
                    Over a 12-week period, you will work within a
                    cross-functional remote team with individuals of varied
                    expertise and skill levels. You will help your team thrive through your decision making,
                    prioritizing, agility, and execution. As a team, you will
                    encounter a range of real-life challenges faced by
                    engineering leaders today.
                  </div>
                  <div>
                    <br/>
                  </div>
                  <div>
                    Engineering leaders ensure optimal performance of
                    teams, tools and processes while maintaining profitability;
                    you will understand the challenges and compromises, and
                    what it takes to become one.
                  </div>
                  <div>
                    <br/>
                  </div>
                  <div>
                    <h4 className="card-title pb-3">Programme Objectives</h4>
                  </div>
                  <ul className="learning-list">
                    <li>
                      <p className="card-text pb-3">
                        Develop and release high quality software.
                      </p>
                    </li>
                    <li>
                      <p className="card-text pb-3">
                        Understand key engineering principles/framework guiding
                        software development.
                      </p>
                    </li>
                    <li>
                      <p>Ability to manage teams and projects using Agile methodolgy.</p>
                    </li>
                    <li>
                      <p>
                        Develop skills that will make you successful on you next
                        software leadership interview.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="HomePage--content-card2">
            <div>
              <img
                src={participant}
                className="WomanONPCIMG"
                alt="WomanONPCIMG"
                
              />
              <br /><br />
              <p className="selp">Recent Graduate - SELP 2020</p>
              <br />
            </div>
          </div>
        </div>
        <div className="container-fluid m-3">
          <div className="row">
            <div className="col-lg-6">
              <div>
                <br/>
              </div>

              <div>
                <h4 className="card-title pb-3" style={{ color: "#800080" }}>
                  Programme Framework
                </h4>
              </div>

              <p className="card-text pb-3">
                The IVYARC Software Engineering Leadership Programme (SELP)
                adopts a team-based approach to support learning and innovation. 
                Participants are grouped into
                teams, tasked with building exciting solutions that resolve 
                real life problems. Each week, you will be required to:
              </p>
              <ul className="learning-list">
                <li>
                  <p className="card-text pb-3">
                    Apply your learned interpersonal and professional skills in
                    a real-world project and development environment that will
                    make you a valuable asset to any team that you work with.
                  </p>
                </li>
                <li>
                  <p>
                    Create results from your own informed
                    discretion, analysis, and effective coordination and
                    communication with like-minded peers.
                  </p>
                </li>
                <li>
                  <p>
                    Multitask and balance your work and life efficiently.
                    Pro-actively managing complex requirements and prioritizing
                    between critical tasks.
                  </p>
                </li>
                <li>
              <p className="card-text pb-3">
                Adopt Agile ways of working and ceremonies, with each
                team taking responsibility for planning their own agile calendar.
              </p>
                </li>
                  
              </ul>
              
             
              <div>
                <br/>
              </div>
              <div>
                <br/>
              </div>
              <div>
                <br/>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="icon-container">
                    <div className="icon-images mb-3">
                      <img
                        src={Openhouse}
                        className="icon-image"
                        alt="Open-House-Surgery"
                      />
                    </div>
                    <p className="text-muted">Open House Surgery</p>
                  </div>
                  <div className="icon-container">
                    <div className="icon-images mb-3">
                      <img
                        src={Daily}
                        className="icon-image"
                        alt="Daily-Stand"
                      />
                    </div>
                    <p className="text-muted">Daily Standup</p>
                  </div>
                  <div className="icon-container">
                    <div className="icon-images mb-3">
                      <img
                        src={retro}
                        className="icon-image"
                        alt="Weekly-Retrospective"
                      />
                    </div>
                    <p className="text-muted">Weekly Retrospective</p>
                  </div>
                  <div className="icon-container">
                    <div className="icon-images mb-3">
                      <img
                        src={sprint}
                        className="icon-image"
                        alt="Weekly-Retrospective"
                      />
                    </div>
                    <p className="text-muted">Sprint Planning</p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="icon-container">
                    <div className="icon-images mb-3">
                      <img
                        src={online}
                        className="icon-image"
                        alt="Online-Group-Learning"
                      />
                    </div>
                    <p className="text-muted">Online Group Learning</p>
                  </div>
                  <div className="icon-container">
                    <div className="icon-images mb-3">
                      <img
                        src={collaborative}
                        className="icon-image"
                        alt="Collaborative-Online-Community"
                      />
                    </div>
                    <p className="text-muted">Team Based Project</p>
                  </div>
                  <div className="icon-container">
                    <div className="icon-images mb-3">
                      <img
                        src={community}
                        className="icon-image"
                        alt="Continuous-improvement-feedback"
                      />
                    </div>
                    <p className="text-muted">Collaborative online community</p>
                  </div>

                  <div className="icon-container">
                    <div className="icon-images mb-3">
                      <img
                        src={feedback}
                        className="icon-image"
                        alt="Continuous-improvement-feedback"
                      />
                    </div>
                    <p className="text-muted">
                      Continuous Improvement/Feedback
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card border-0">
                <div className="card-body border-0">
                  <h3 className="card-title pb-3">Learning Journey</h3>

                  <h5 className="pb-3" style={{ color: "#274e13" }}>
                    Week 1 – Agile Methodology
                  </h5>
                  <p className="card-text pb-1  ">
                    Master the Agile Development methodology and adopt a
                    flexible mindest for delivery on professional and personal
                    projects.{" "}
                  </p>
                  <h5 className="pb-3" style={{ color: "#37761d" }}>
                    Week 2 - Re-Introduction to Web Development
                  </h5>
                  <p className="card-text pb-1">
                    Study the core web programming – HTML and CSS. Start
                    creating and optimizing your website development with the
                    support of an expert team to guide you every step of the
                    way.
                  </p>
                  <h5 className="pb-3" style={{ color: "#69a84f" }}>
                    Week 3 - Re-Introduction to Object Oriented Programming
                    (OOP)
                  </h5>
                  <p className="card-text pb-1">
                    Explore one of programming’s greatest tools – Object
                    Oriented Programming (OOP). You will apply this knowledge
                    via two popular scripting languages – JavaScript and
                    TypeScript.
                  </p>
                  <h5 className="pb-3" style={{ color: "#6da191" }}>
                    Week 4 - CI/CD Pipeline using GitHub
                  </h5>
                  <p className="card-text pb-1">
                    Master Continuous Integration and Continuous Delivery to
                    imrpove team collaboration and streamline delivery.
                  </p>
                  <h5 className="pb-3" style={{ color: "#5389a8" }}>
                    Week 5 - React (Introduction)
                  </h5>
                  <p className="card-text pb-1">
                    Build & Deploy solutions fast, using React – one of the
                    world’s leading JavaScript libraries that will ease your
                    software building process whilst still boosting your
                    productivity.
                  </p>
                  <h5 className="pb-3" style={{ color: "#3d85c6" }}>
                    Week 6 - React (Components and Events)
                  </h5>
                  <p className="card-text pb-1">
                    Continuing from the previous week, you will delve further
                    into the concepts and various tools within the React
                    framework that you can leverage towards your programming
                    benefit.
                  </p>
                  <h5 className="pb-3" style={{ color: "#3574e3" }}>
                    Week 7 - React (Management and Library)
                  </h5>
                  <p className="card-text pb-1">
                    Finalise your React knowledge by mastering its various
                    libraries and management tools, like Redux, that you can
                    apply to real-world projects to optimize your team’s output.
                  </p>

                  <h5 className="pb-3" style={{ color: "#580258" }}>
                    Week 8 - Node.js (Introduction)
                  </h5>
                  <p className="card-text pb-1">
                    Get introduced to a leading open-source, cross-platform
                    technology for building scalable network applications.
                  </p>

                  <h5 className="pb-3" style={{ color: "#580257" }}>
                    Week 9 - Node.js (Frameworks)
                  </h5>
                  <p className="card-text pb-1">
                    Leveraging on your knowledge of the previous week, you will
                    delve further into the helpful utilities within the
                    development environment to boost your productivity.
                  </p>

                  <h5 className="pb-3" style={{ color: "#37761d" }}>
                    Week 10 - Node.js (Server Communication)
                  </h5>
                  <p className="card-text pb-1">
                    Finalise your Node.js knowledge by mastering the
                    browser-server communication channel using real-time
                    event-driven technologies.
                  </p>

                  <h5 className="pb-3" style={{ color: "#37761d" }}>
                    Week 11 – Software Testing and Deployment
                  </h5>
                  <p className="card-text pb-1">
                    Notice how nothing is perfect right from the get-go? Then
                    that’s where testing comes in. Find out what could be wrong,
                    improve on it, and deploy to the public, easily and fluidly.
                  </p>

                  <h5 className="pb-3" style={{ color: "#3574e3" }}>
                    Week 12 - Career Services
                  </h5>
                  <p className="card-text pb-1">
                    In the final week, you will also be given practical
                    personalised guidance on optimising your LinkedIn page and a
                    personal CV/Resume review to boost your employability to key
                    talent seekers, regardless of professional industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid row shadow p-1 m-0 bg-light rounded">
          <div className="col-12">
            <div className="intern-header-design">
              <h3
                style={{
                  color: "#5a025a",
                  marginBottom: "30px",
                  textAlign: "center",
                  fontSize: "32px",
                }}
              >
                Hear from our Graduates...
              </h3>
            </div>
            <div>
              <OwlCarousel
                className="owl-theme"
                loop
                autoplay={true}
                items={1}
                margin={10}
              >
                <div className="imgCont">
                  <div className="carousel-img">
                    <img
                      className="graduate-img"
                      src={kenn}
                      alt="career-placement"
                    />
                  </div>
                  <div className="imgTextCont">
                    <h2 className="font-weight-bold">Aladi Kenneth</h2>
                    <h6 className="text-muted pb-3 font-weight-bold">
                      {" "}
                      Placed Graduate
                    </h6>
                    <p className="text-muted">
                      <span
                      className="iconify"
                      data-icon="fluent:text-quote-20-filled"
                      data-width="30"
                      data-height="40"
                      data-rotate="180deg"
                      style={{
                        marginRight: "10px",
                        marginBottom: "-10px",
                        color: "#aafcff",
                      }}
                      />
                      <i
                        style={{
                          fontfamily: "serif",
                          fontSize: "20px",
                          fontStyle: "inherit",
                          textAlign: "center",
                          alignContent: "center",
                          
                        }}
                      >
                        It has been an awesome feeling for me to join a team of
                        great minds across the globe on a world class project.
                        Big thanks to Team Ivyarc Africa for the placement
                        opportunity with Xische & Co after the programme. The
                        people in the team are very supportive and always gave
                        swift responses to my work needs enusring I have all I
                        need to do my job properly. Thank you IvyArc Africa!
                      </i>
                      <span
                      className="iconify"
                      data-icon="fluent:text-quote-20-filled"
                      data-width="20"
                      data-height="40"
                      style={{
                        marginBottom: "-20px",
                        marginLeft: "10px",
                        color: "#aafcff",
                      }}
                      />
                    </p>
                  </div>
                </div>
                <div className="imgCont">
                  <div className="carousel-img">
                    <img
                      className="graduate-img"
                      src={ayo}
                      alt="career-placement"
                    />
                  </div>
                  <div className="imgTextCont">
                    <h2 className="font-weight-bold">Ajibade Ayoyimika</h2>
                    <h6 className="text-muted pb-3 font-weight-bold">
                      Graduate
                    </h6>
                    <p className="text-muted">
                      <span
                        className="iconify"
                        data-icon="fluent:text-quote-20-filled"
                        data-width="30"
                        data-height="40"
                        data-rotate="180deg"
                        style={{
                          marginRight: "10px",
                          marginBottom: "-10px",
                          color: "#aafcff",
                        }}
                      />
                      <i
                        style={{
                          fontfamily: "serif",
                          fontSize: "20px",
                          fontStyle: "inherit",
                          fontWeight: "bold",
                        }}
                      >
                        It was quite a challenging but interesting journey for me. 
                        The daily tasks, and challenges to build software products with an
                        Agile philosophy which I believe is a great step in achieveing success 
                        in projects was enlightening. 
                        I enjoyed being taught with the latest technology in line with industry
                        standard and the tips and practical examples shared by the experts across different fields.
                      </i>
                      <span
                        className="iconify"
                        data-icon="fluent:text-quote-20-filled"
                        data-width="20"
                        data-height="40"
                        style={{
                          marginBottom: "-20px",
                          marginLeft: "10px",
                          color: "#aafcff",
                        }}
                      />
                    </p>
                  </div>
                </div>
                <div className="imgCont">
                  <div className="carousel-img">
                    <img
                      className="graduate-img"
                      src={gabriel}
                      alt="career-placement"
                    />
                  </div>
                  <div className="imgTextCont">
                    <h2 className="font-weight-bold">Chia Gabriel</h2>
                    <h6 className="text-muted pb-3 font-weight-bold">
                      Graduate
                    </h6>
                    <p className="text-muted">
                      <span
                        className="iconify"
                        data-icon="fluent:text-quote-20-filled"
                        data-width="30"
                        data-height="40"
                        data-rotate="180deg"
                        style={{
                          marginRight: "10px",
                          marginBottom: "-10px",
                          color: "#aafcff",
                        }}
                      />
                      <i className="testimonial">
                        I am glad to have been part of the programme. l have
                        learnt quite a lot within a short period from the
                        research and materials provided. it was a worthy
                        opportunity and I appricate team IvyArc for it.
                      </i>
                      <span
                        className="iconify"
                        data-icon="fluent:text-quote-20-filled"
                        data-width="20"
                        data-height="40"
                        style={{
                          marginBottom: "-20px",
                          marginLeft: "10px",
                          color: "#aafcff",
                        }}
                      />
                    </p>
                  </div>
                </div>
                <div className="imgCont">
                  <div className="carousel-img">
                    <img
                      className="graduate-img"
                      src={joshua}
                      alt="career-placement"
                    />
                  </div>
                  <div className="imgTextCont">
                    <h2 className="font-weight-bold">Joshua Balogun</h2>
                    <h6 className="text-muted pb-3 font-weight-bold">
                      Graduate
                    </h6>
                    <p className="text-muted">
                      <span
                        className="iconify"
                        data-icon="fluent:text-quote-20-filled"
                        data-width="30"
                        data-height="40"
                        data-rotate="180deg"
                        style={{
                          marginRight: "10px",
                          marginBottom: "-10px",
                          color: "#aafcff",
                        }}
                      />
                      <i className="testimonial">
                        It was an opportunity for me to learn something
                        different. I was glad to be part of a young minded and
                        innovative team. I met new people that are now
                        instrumental to my career growth.
                      </i>
                      <span
                        className="iconify"
                        data-icon="fluent:text-quote-20-filled"
                        data-width="30"
                        data-height="40"
                        style={{
                          marginBottom: "-20px",
                          marginLeft: "10px",
                          color: "#aafcff",
                        }}
                      />
                    </p>
                  </div>
                </div>
                <div className="imgCont">
                  <div className="carousel-img">
                    <img
                      className="graduate-img"
                      src={kazeem}
                      alt="career-placement"
                    />
                  </div>
                  <div className="imgTextCont">
                    <h2 className="font-weight-bold">
                      Erinfolami Kazeem Bolaji
                    </h2>
                    <h6 className="text-muted pb-3 font-weight-bold">
                      Graduate
                    </h6>
                    <p className="text-muted">
                      <span
                        className="iconify"
                        data-icon="fluent:text-quote-20-filled"
                        data-width="30"
                        data-height="40"
                        data-rotate="180deg"
                        style={{
                          marginRight: "10px",
                          marginBottom: "-10px",
                          color: "#aafcff",
                        }}
                      />
                      <i className="testimonial">
                        The programme instructors/tutors are very freindly and
                        supportive. Even as a newbie, I felt very comfortable
                        making contributions to the team because everyone's
                        opinion is valued.
                      </i>
                      <span
                        className="iconify"
                        data-icon="fluent:text-quote-20-filled"
                        data-width="20"
                        data-height="40"
                        style={{
                          marginBottom: "-20px",
                          marginLeft: "10px",
                          color: "#aafcff",
                        }}
                      />
                    </p>
                  </div>
                </div>
                <div className="imgCont">
                  <div className="carousel-img">
                    <img
                      className="graduate-img"
                      src={olusola}
                      alt="career-placement"
                    />
                  </div>
                  <div className="imgTextCont">
                    <h2 className="font-weight-bold">Olusola Itunu</h2>
                    <h6 className="text-muted pb-3 font-weight-bold">
                      Graduate
                    </h6>
                    <p className="text-muted">
                      <span
                        className="iconify"
                        data-icon="fluent:text-quote-20-filled"
                        data-width="30"
                        data-height="40"
                        data-rotate="180deg"
                        style={{
                          marginRight: "10px",
                          marginBottom: "-10px",
                          color: "#aafcff",
                        }}
                      />
                      <i className="testimonial">
                        The learning methodolgy made it quite easy for me to get
                        on well on the programme despite my minimal IT
                        background. I have definitely found a new career path
                        and happy to pursue it to greater heights.
                      </i>
                      <span
                        className="iconify"
                        data-icon="fluent:text-quote-20-filled"
                        data-width="30"
                        data-height="40"
                        style={{
                          marginBottom: "-20px",
                          marginLeft: "10px",
                          color: "#aafcff",
                        }}
                      />
                    </p>
                  </div>
                </div>
                <div className="imgCont">
                  <div className="carousel-img">
                    <img
                      className="graduate-img"
                      src={oyekan}
                      alt="career-placement"
                    />
                  </div>
                  <div className="imgTextCont">
                    <h2 className="font-weight-bold">Oyekan Emmanuel</h2>
                    <h6 className="text-muted pb-3 font-weight-bold">
                      Graduate
                    </h6>
                    <p className="text-muted">
                      <span
                        className="iconify"
                        data-icon="fluent:text-quote-20-filled"
                        data-width="30"
                        data-height="40"
                        data-rotate="180deg"
                        style={{
                          marginRight: "10px",
                          marginBottom: "-10px",
                          color: "#aafcff",
                        }}
                      />
                      <i className="testimonial">
                        The internship programme for me was worth the while!!!
                        The excellent Team and structured learning enabled me
                        track my progress which was very encouraging and
                        motivating.
                      </i>
                      <span
                        className="iconify"
                        data-icon="fluent:text-quote-20-filled"
                        data-width="30"
                        data-height="40"
                        style={{
                          marginBottom: "-20px",
                          marginLeft: "10px",
                          color: "#aafcff",
                        }}
                      />
                    </p>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        <div>
          <div
            className="container-fluid row shadow p-3 ml-0 mb-3 bg-white rounded"
            style={{ marginTop: "30px" }}
          >
            <div className="col-12">
              <div className="intern-header-design">
                <h3
                  style={{
                    color: "#5a025a",
                    marginBottom: "60px",
                    textAlign: "center",
                    fontSize: "32px",
                  }}
                >
                  Our Clients/Partners
                </h3>
              </div>
              <div className="container-fluid row">
                <div className="col-6">
                  <div className="client-item-left">
                    <img
                      className="client-item-img"
                      src={xische}
                      alt="partners/client"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="client-item-right">
                    <img
                      className="client-item-img"
                      src={workang}
                      alt="partners/client"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="card-group shadow p-3 mb-5 bg-white rounded">
            <div className="card">
              {/* <div className="card-img-block1"></div>
              <div className="card-img-block-1"></div>
              <div className="card-img-block-21"></div> */}
              <img
                className="card-img-top"
                src={pic1}
                height="auto"
                width="auto"
                alt="Active-Learning"
              />
              <div className="card-body">
                <h2 className="card-title pb-2" style={{ color: "#274e13" }}>
                  Programme Benefits
                </h2>
                <h2 className="card-title pb-2" style={{ color: "#3574e3" }}>
                  Active
                  <br /> Remote Learning
                </h2>
                <h5 className="card-subtitle mb-3 text-muted border-bottom-0 pb-2">
                  We bring the knowledge to you
                </h5>
                <p className="card-text">
                  Experience a diverse set of active educational tasks ranging
                  from interactive online classes, mentor-supported software
                  development, and hands-on software project participation, to
                  grow into a full-formed and highly capable Full Stack Software
                  Developer.
                </p>
              </div>
            </div>
            <div className="card">
              {/* <div className="card-img-block2"></div>
              <div className="card-img-block-12"></div>
              <div className="card-img-block-2"></div>
              <div className="card-img-block-212"></div> */}
              <img
                className="card-img-top"
                src={pic2}
                height="auto"
                width="auto"
                alt="Virtual-Global-Collaboration"
              />
              <div className="card-body">
                <h2 className="card-title pb-2" style={{ color: "#580258" }}>
                  Active <br /> Interaction with Industry Experts
                </h2>
                <h5 className="card-subtitle mb-3 text-muted border-bottom-0 pb-2">
                  Learn from the Best
                </h5>
                <p className="card-text">
                  Through our open-house surgeries, freely interact with active
                  industry experts and trailblazers that are leading the charge
                  in global IT innovations. Experience is the best teacher, so
                  come and learn from that of the best, optimising both your
                  career and personal potential
                </p>
              </div>
            </div>
            <div className="card">
              {/* <div className="card-img-block3"></div>
              <div className="card-img-block-31"></div>
              <div className="card-img-block-13"></div> */}
              <img
                className="card-img-top"
                src={pic3}
                height="auto"
                width="auto"
                alt="Becoming-belonging"
              />
              <div className="card-body">
                <h2 className="card-title pb-2" style={{ color: "#6da191" }}>
                  Peer <br /> Networking
                </h2>
                <h5 className="card-subtitle mb-3 text-muted border-bottom-0 pb-2">
                  Join our Family
                </h5>
                <p className="card-text">
                  Join our diverse community, consisting of both our graduated
                  and successfully placed students, internal and external
                  industry experts, and our international clients, to enjoy a
                  highly supportive personal and professional network and
                  opportunity pool
                </p>
              </div>
            </div>
          </div>
          <div className="welcome--meetup-container">
            <div className="welcome--meetup-wrapper">
              <div className="welcome--meetup-card1">
                <Link to="/RegistrationForm">
                  <button>
                    Apply Now
                    <span
                      className="iconify welcome--meetup-applyNow-icon"
                      data-icon="clarity:cursor-arrow-solid"
                      data-inline="false"
                    />
                  </button>
                </Link>
                <div className="welcome--meetup-Deadline">
                  *Application Deadline
                </div>
                <div className="welcome--meetup-time">Dec 2nd, 2021</div>
                <div className="welcome--meetup-time">2021 at 11:59pm WAT.</div>
              </div>
              <div className="welcome--meetup-card2">
                <div className="welcome--meetup-card2-content">
                  <div>
                    <span
                      className="iconify welcome--meetup-card2-icon"
                      data-icon="carbon:user-profile"
                      data-inline="false"
                    />
                  </div>
                  <div className="welcome--meetup-card2-content-text">
                    Create profile
                  </div>
                </div>

                <div className="welcome--meetup-card2-content">
                  <div>
                    <span
                      className="iconify welcome--meetup-card2-icon"
                      data-icon="vaadin:presentation"
                      data-inline="false"
                    />
                  </div>
                  <div className="welcome--meetup-card2-content-text">
                    Take a video interview
                  </div>
                </div>
                <div className="welcome--meetup-card2-content">
                  <div>
                    <span
                      className="iconify welcome--meetup-card2-icon"
                      data-icon="fluent:payment-28-filled"
                      data-inline="false"
                    />
                  </div>
                  <div className="welcome--meetup-card2-content-text">
                    Pay for course
                  </div>
                </div>
                <div className="welcome--meetup-card2-content">
                  <div>
                    <span
                      className="iconify welcome--meetup-card2-icon"
                      data-icon="bx:bx-code-alt"
                      data-inline="false"
                    />
                  </div>
                  <div className="welcome--meetup-card2-content-text">
                    Commence programme
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='faq--cont'>
            <Link to='/FAQ'>
              <button type='link' className='submit2'>
                FAQ
              </button>
            </Link>
          </div>
        </div>
<br/>

        <div className="HomePage--content-footer">
          <div className="home-page-footer">
            @ivyarcafrica © {currentYear}, All rights reserved. <br/>
            <a href = "" target ="_blank">IVYARC SELP</a> <br/>
             <p> +234 808 989 5660, +44 7744 4151 270</p>
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
