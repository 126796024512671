import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ReactGA from 'react-ga';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './pages.js/LandingPage/HomePage';
import RegistrationForm from './RegistrationForm.js/RegistrationForm';
import CourseSyllabus from "./pages.js/Registration/CourseSyllabus";
import FAQ from './pages.js/FAQ';

const TRACKING_ID = "UA-179932347-2"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

ReactGA.event({
  category: 'User',
  action: 'Created an Account'
});

ReactGA.exception({
  description: 'An error ocurred',
  fatal: true
});

ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/RegistrationForm" component={RegistrationForm} />
        <Route path="/CourseSyllabus" component={CourseSyllabus} />
        <Route path="/FAQ" component={FAQ} />
        
      </Switch>
    </BrowserRouter>
  );
}

export default App;
